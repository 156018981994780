import { notify } from "notiwind";
import { analyticsApiService } from "~/services/analyticsApiService";
import { notifyError } from "~/utils/notifications";

export const useAnalyticsApi = () => {
  const data = ref(null);
  const error = ref(null);
  const loading = ref(false);

  const fetchData = async (fetchFunction, options) => {
    loading.value = true;
    error.value = null;
    try {
      const response = await fetchFunction(options);
      if (response.status === 200) data.value = response.data?.data;
      else
        notifyError({
          title: response.data?.message || "An unknown error occurred.",
        });
    } catch (err) {
      error.value = err.message;
      notifyError();
    } finally {
      loading.value = false;
    }
  };

  const fetchBasicData = (options) =>
    fetchData(analyticsApiService().fetchBasicData, options);
  const fetchClicksData = (options) =>
    fetchData(analyticsApiService().fetchClicksData, options);
  const fetchLocationsData = (options) =>
    fetchData(analyticsApiService().fetchLocationsData, options);
  const fetchTrafficSourcesData = (options) =>
    fetchData(analyticsApiService().fetchTrafficSourcesData, options);

  return {
    data,
    error,
    loading,
    fetchBasicData,
    fetchClicksData,
    fetchLocationsData,
    fetchTrafficSourcesData,
  };
};
