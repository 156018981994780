import { storeToRefs } from "pinia";
import { useAuthStore } from "~/store/auth.js";
import { useAnalyticsStore } from "~/store/useAnalyticsStore.js";

export const analyticsApiService = () => {
  if (process.server) return;

  const { sendGetRequest } = useSendRequest();
  const { header } = storeToRefs(useAuthStore());
  const { selectedDateRange, filters } = storeToRefs(useAnalyticsStore());

  const apis = new APIS();
  const BASE_URL = apis.BASE_URL;
  const ENDPOINTS = {
    BASIC: apis.BASIC_ANALYTICS_DATA,
    CLICK: apis.CLICKS_ANALYTICS_DATA,
    LOCATION: apis.LOCATIONS_ANALYTICS_DATA,
    TRAFFIC_SOURCES: apis.TRAFFIC_SOURCES_ANALYTICS_DATA,
  };

  const handleError = (error) => {
    throw new Error(error?.response?.data?.message || "API request failed");
  };

  const getConfig = ({ collectionId = null, linkId = null }) => ({
    headers: header.value,
    responseType: "json",
    params: {
      period: JSON.stringify({
        startDatetime: String(selectedDateRange.value.startDate),
        endDatetime: String(selectedDateRange.value.endDate),
      }),
      filters: JSON.stringify(filters.value),
      collection_id: collectionId,
      smartlnk_id: linkId,
    },
  });

  const makeFetchRequest = async (endpoint, config) => {
    try {
      const response = await sendGetRequest(`${BASE_URL}${endpoint}`, config);
      return response;
    } catch (error) {
      handleError(error);
    }
  };

  const fetchBasicData = (options) =>
    makeFetchRequest(ENDPOINTS.BASIC, getConfig(options));
  const fetchClicksData = (options) =>
    makeFetchRequest(ENDPOINTS.CLICK, getConfig(options));
  const fetchLocationsData = (options) =>
    makeFetchRequest(ENDPOINTS.LOCATION, getConfig(options));
  const fetchTrafficSourcesData = (options) =>
    makeFetchRequest(ENDPOINTS.TRAFFIC_SOURCES, getConfig(options));

  return {
    fetchBasicData,
    fetchClicksData,
    fetchLocationsData,
    fetchTrafficSourcesData,
  };
};
